/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const STORAGE_CONFIG = {
    // bucket: 'omnitesting',
    bucket: 'omni-documents',
    region: 'ap-southeast-1',
};

export default STORAGE_CONFIG;
