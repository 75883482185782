import { Auth } from 'aws-amplify';
import React from 'react';
import { ErrorAppCrashIllustration } from '../../assets/icons/icons';
import { IcoMoon } from '../../icons';

const wrapperStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '8px',
};

const titleStyle: React.CSSProperties = {
    fontWeight: 700,
    fontSize: '24px',
    color: '#002043',
    marginTop: '33px',
    lineHeight: '32px',
};

const fontStyle: React.CSSProperties = {
    fontWeight: 400,
    fontSize: '16px',
    color: '#002043',
    lineHeight: '24px',
};

const linkStyle: React.CSSProperties = {
    color: '#0089EC',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0em',
};
const linkWrapper: React.CSSProperties = {
    marginTop: '16px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const title = 'Something went wrong';
const errorCode = {
    label: 'Error code:',
    code: 'R005',
};
const errorMessage_1 = `Sorry, we encountered an unexpected error.`;
const errorMessage_2 = `Please contact support for more details.`;
const backToLogin = 'Back To Login';

const clearCache = async () => {
    await Auth.signOut();
    localStorage.removeItem('session');
    localStorage.clear();
    sessionStorage.clear();
};

interface FallbackProps {
    error: Error;
    resetErrorBoundary: (...args: Array<unknown>) => void;
}

export const ErrorBoundaryApp = ({ error, resetErrorBoundary }: FallbackProps) => {
    console.log(error);
    return (
        <div style={wrapperStyle}>
            <div style={{ fontSize: '0', textAlign: 'center' }}>
                <ErrorAppCrashIllustration />
                <div style={titleStyle}>{title}</div>
                <div style={{ ...fontStyle }}>
                    {errorMessage_1}
                    <br />
                    {errorMessage_2}
                </div>
                <div style={{ marginTop: '16px', ...fontStyle }}>
                    {errorCode.label}
                    <div style={{ display: 'inline-block', ...fontStyle, fontWeight: 700 }}>{errorCode.code}</div>
                </div>
                <div
                    style={linkWrapper}
                    onClick={async () => {
                        await clearCache();
                        resetErrorBoundary();
                        window.location.replace('/');
                    }}
                >
                    <IcoMoon size={'18px'} name="arrow-left" color="#0089EC" style={{ marginRight: '4px' }} />
                    <div style={linkStyle}>{backToLogin}</div>
                </div>
            </div>
        </div>
    );
};
