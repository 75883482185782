// export const COGNITO_CONFIG = {
//     aws_cognito_region: 'ap-southeast-1',
//     aws_user_pools_id: 'ap-southeast-1_p35sOAD4D',
//     aws_user_pools_web_client_id: '73lv1nlsi1lkcpa1jbfb5tf69i',
//     aws_cognito_identity_pool_id: 'ap-southeast-1:ba2d26a0-6fb7-46c2-a25c-b3ad959e89ed',
// };

export const COGNITO_CONFIG = {
    aws_cognito_region: 'ap-southeast-1',
    aws_user_pools_id: 'ap-southeast-1_blipqra6O',
    aws_user_pools_web_client_id: '4altcj3ct2vj88m2u7b4gpqpq',
    aws_cognito_identity_pool_id: 'ap-southeast-1:5f14d45b-3ef3-43f9-ab24-73edf12e0a22',
};
