/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    aws_project_region: 'ap-southeast-1',
    aws_appsync_graphqlEndpoint: 'https://k5ebi76zbjcdrpfr5epiykah2q.appsync-api.ap-southeast-1.amazonaws.com/graphql',
    aws_appsync_region: 'ap-southeast-1',
    aws_appsync_authenticationType: 'AWS_IAM',
};

export default awsmobile;
